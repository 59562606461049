import React, { Fragment } from "react";
import './infoBar.css'

const Info = (props) => {

return (

    <Fragment><div>{props.legend}:<br /> {props.children}</div><div className="divider">|</div></Fragment>

);

}

export default Info