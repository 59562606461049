import React from 'react'
import logoImage from '../../images/Logo.png'
import './logo.css'



const Logo = () => {

    return (

        <div className='header'>

            <img src={logoImage} className="Logo" alt="Logo" />

        </div>


    );
}

export default Logo