import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './pages/App';
import About from './pages/team';
import { store } from './app/store'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './Components/header/header';
import Footer from './Components/footer/footer';
import FaqsContent from './Components/content/faqsContent/faqsContent';
import {Helmet} from 'react-helmet';


ReactDOM.render(

  <Provider store={store}>
<Helmet>
<title>5 Senses social Care</title>
<meta
      name="description"
      content="5 Senses is a social care setting for adults with disabilities. we offer a wide range of activities orientated around the individual." />


</Helmet>

<CssBaseline />
  <Router>
    
      <Header />

      <Switch>

        <Route exact path='/' component={Home} />  
        <Route path='/#about' />     
        <Route path='/meet-the-team' component={About} />
        <Route path='/#vacancies' />
        <Route path='/faqs' component={FaqsContent} />
        <Route path='/#testimonials' />
        
      </Switch>
      
      
    <Footer />
  </Router>
  
  </Provider>,
  document.getElementById('root')

);


