import React, { useState } from "react";
import "./testimonialCard.css";
import FormatQuoteOutlined from "@mui/icons-material/FormatQuoteOutlined";
import avatar from "../../images/img_avatar5.png";
import { Modal, Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const TestimonialCard = (props) => {

	
        
	const [open, setOpen] = useState(false);
	const [backdrop] = useState(true);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	

	return (
		<>
			<div className="modal-container">
			
				<Modal
					backdrop={backdrop}
					keyboard={false}
					open={open}
					onClose={handleClose}
				>
					<Modal.Header>
						<Modal.Title>
							<img src={avatar} alt="testimonials" className="avatar" />
							<br />
							{props.name}
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p className="card-text" onClick={handleOpen}>
							<FormatQuoteOutlined className="quote-top" />
							{props.children}
							<FormatQuoteOutlined className="quote-bottom" />
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={handleClose} appearance="primary">
							Ok
						</Button>
					</Modal.Footer>
				</Modal>
			</div>

			<div
				className="card text-dark bg-info2 mb-3 rounded testimonial"
				style={props.style}
				onClick={handleOpen}
				
			>
				<div className="card-body bg-light border border-info">
					<img src={avatar} alt="testimonials" className="avatar" />
					<h5 className="card-title border-bottom border-info pb-2 ">
						{props.name}
					</h5>
					<p className="card-text truncate">
						{/* <FormatQuoteOutlined className="quote-top" />
						{props.children}
						<FormatQuoteOutlined className="quote-bottom" /> */}
<br />
						click to read
					</p>
				</div>
			</div>
		</>
	);
};

export default TestimonialCard;
