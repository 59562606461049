import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import "./gallery.css";
import TestimonialCard from "../testimonials/testimonialCard";

const Gallery = (props) => {
  const [display, setDisplay] = useState(4);

  const [width, setWidth] = useState(window.innerWidth);
  const mobile = 480;
  const tablet = 660;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    if (width < mobile) {
      setDisplay(1);
      
      setStart(0);
      setFinish(display);
      return () => window.removeEventListener("resize", handleWindowResize);
    }

    if (width > mobile && width < tablet) {
      setDisplay(1);
      
      setStart(0);
      setFinish(display);
      return () => window.removeEventListener("resize", handleWindowResize);
    } else if ( width > tablet ) {
      setDisplay(3);
      
      setStart(0);
      setFinish(display);
      return () => window.removeEventListener("resize", handleWindowResize);
    } 
  }, [width, display]);

  const [start, setStart] = useState(0);
  const [finish, setFinish] = useState(display);

  const testimonials = [
    {
      name: "Mr S A.",
      text: "I Think 5 Senses is fab and wonderful, it is like my family. Im glad I come to 5 Senses because it is fun and makes me happy from day 1.",
    },
    {
      name: "Mr PHEASANT",
      text: "I like coming to 5 Senses to see my friends and staff, I feel happy and I like to try all the new things I have not done before.",
    },
    {
      name: "Mrs M SUMNER",
      text: "Anthony is really enjoying his new day care with 5 Senses. He was refusing point blank to go back to his previous day centre after lockdown. Not going anywhere was not an option as for him he just didn’t go outside the house and spent his time cooped up his sitting room watching tv and playing on his game station. The staff from 5 Senses came to Anthony every week until he decided he would go and visit the house – just for a coffee. Fast forward 3 months and Anthony is really happy there, going for 2 days a week and has now asked to go another day!!! What a difference. A great big thank you to Vicki and Cath and their team for their determination, patience and care you have given Anthony. Thank you, thank you and thank you again.",
    },
    {
      name: "Mrs J Mcginn",
      text: "5Senses is an outstanding organisation which offers great support and wellbeing for all its clients. 5Senses has allowed my son who is a vulnerable young adult with additional learning difficulties, to interact with other clients that attend within the local community. They offer a wide variety of daily fun activities which my son enjoys getting involved in.",
    },
    {
      name: "Daniel Mcginn",
      text: "I really enjoy going to this group, it has helped me make new friends. All the staff take the time to help us with the daily activities. I'm always laughing now as i absolutely love going to the group. 5Senses has changed my life as before i would just sit in my bedroom and not go out. I have a purpose to get up now knowing that i'm going to have a good day.",
    },
    {
      name: "A",
      text: "5 Senses is a wonderful enviroment, it's a safe space to unmask and relax. I look forward to it every week.",
    },
    {
      name: "Linda Oaks",
      text: "A huge thank you for the wonderful, much needed, service you provide. Our son enjoys his time with you and you all make it such a personal experience that he consideres you his 'friends' - a high accolade!. A very considerate, caring service, highly recommended!.",
    },
  ];

  const cards = testimonials.map((item, index) => (
    <div className="test-card" key={index}>
      <TestimonialCard name={item.name}>{item.text}</TestimonialCard>
    </div>
  ));

  const handleRightArrowPress = () => {
    if (finish <= cards.length - 1) {
      if (finish + display > cards.length - 1) {
        setStart(cards.length - display);
        setFinish(cards.length);
      } else {
        setStart(start + display);
        setFinish(finish + display);
      }
    } else {
      setStart(0);
      setFinish(display);
    }
  };

  const handleLeftArrowPress = () => {
    if (start > 0) {
      if (start - display < 0) {
        setStart(0);
        setFinish(display);
      } else {
        setStart(start - display);
        setFinish(finish - display);
      }
    } else {
      setStart(cards.length - display);
      setFinish(cards.length);
    }
  };

  return (
    <div className="gallery-container fluid w-100" id="testimonials">
      <span
        aria-hidden="true"
        className="carousel-control-prev-icon"
        onClick={handleLeftArrowPress}
      ></span>
      {cards.slice(start, finish)}
      <span
        aria-hidden="true"
        className="carousel-control-next-icon"
        onClick={handleRightArrowPress}
      ></span>
    </div>
  );
};

export default Gallery;
