import React from 'react';
import './columnList.css'

const ColumnList = () => {

return(


<div className="align-items-center">
    <div>
        <ul>
 <li />Animal therapy				
 <li />Travel training				
 <li />Ninja warrior				
 <li />Baking					
 <li />Cook and lunch			
 <li />Pantomimes				
 <li />Walking/Hiking 			
 <li />Farm visits				
 <li />Inflatable parks				
</ul>
    </div>
    <div>
        <ul>
    <li />Money skills
    <li />Meals out
    <li />DIY
    <li />Gardening
    <li />Sensory Experiences
    <li />Hydrotherapy
    <li />Golf
    <li />Arts and crafts
    <li />Cinema visits
    </ul>
    </div>    
  </div>

);
}

export default ColumnList;
