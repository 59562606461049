import React from "react";
import './navbar.css';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { HashLink } from 'react-router-hash-link';

const NavbarItems = () => {

return (

<> 
<Navbar className="bg-info text-white " expand="md">
 

  <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
      <LinkContainer to="/">
        <Nav.Link>Home</Nav.Link>
      </LinkContainer>

      <HashLink smooth to="/#about" className="nav-link">
      About Us
      </HashLink>

      <LinkContainer to="/meet-the-team">
        <Nav.Link>Meet The team </Nav.Link>
        
      </LinkContainer>
      <HashLink smooth to="/meet-the-team#vacancies" className="nav-link">
      Vacancies
      </HashLink>
      <LinkContainer to="/faqs">
        <Nav.Link>FAQs</Nav.Link>
      </LinkContainer>
      <HashLink smooth to="/#testimonials" className="nav-link">
      Testimonials
      </HashLink>
      </Nav>
    </Navbar.Collapse>
  
</Navbar>  


</>
);

}

export default NavbarItems