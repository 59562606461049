import React from "react";
import './gallery.css'


const CarouselText = () => {


return (

<div className="carouselText">
    

    5 Senses offer a wide range of activities focusing on individual skills and abilities, helping to develop personal health and well being.
 <br />
 We aim to provide a person centered, fun and friendly service for adults with disabilities.<br />
 Promoting independence and choice.

</div>



);

}

export default CarouselText