import React from 'react'
import MeetTheTeam from '../Components/content/meetTheTeam/meetTheTeam'
import './App.css'
import {Helmet} from 'react-helmet';

const About = () => {



    return (
      
      <>
      <Helmet>
<title>5 Senses social Care</title>
<meta
      name="description"
      content="5 Senses is a social care setting for adults with disabilities. we offer a wide range of activities orientated around the individual." />


</Helmet>  
        <div className="App-content">

          <MeetTheTeam />
        
        </div>
        
     </>
    );
  }
  
  export default About;