import React from "react";
import "./content.css";
import "../../pages/App.css";
import TestimonialGallery from "../carousel/testimonialGallery";
import BootCarousel from "../../Components/carousel/bootCarousel";
import HomeText from "../../Components/homeText/homeText";
import CarouselText from "../carousel/carouselText";
import Spacer from "../hoc/spacer";
import ImageMasonry from "../masonry/imageMasonry";
import FrameBottom from "../hoc/frameBottom";


const Content = ({ children }) => {
  return (
    <>
      <div>
        <div>
          <div className="App-content">
            <div className="centered">
              <br />
              <div className="container carousel-grid">
                <div className="grid-left">
                  <FrameBottom>
                    <CarouselText />
                  </FrameBottom>
                </div>
                <div className="grid-right">



                  <BootCarousel />
                  

                </div>
              </div>
              <br />
              <div className='activities'>
                <HomeText />
              </div>

              <Spacer />
              <FrameBottom className="customStyle masonry">
                <ImageMasonry />
              </FrameBottom>
              <Spacer />

              <h2>Testimonials</h2>
              <Spacer />
              <TestimonialGallery />
              <Spacer />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
