import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Masonry from '@mui/lab/Masonry';
import { Modal, Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import './imageMasonry.css'

import image1 from '../../images/gdrive-pics/IMG_5286.jpg'
import image6 from '../../images/gdrive-pics/IMG_5287.jpg'
import image3 from '../../images/gdrive-pics/IMG_5288.jpg'

import image4 from '../../images/gdrive-pics/IMG_5290.jpg'

import image7 from '../../images/gdrive-pics/IMG_5293.jpg'
import image8 from '../../images/gdrive-pics/IMG_5294.jpg'
import image9 from '../../images/gdrive-pics/Cornbrook/ANSM2950.JPG'
import image10 from '../../images/gdrive-pics/Cornbrook/GZWC7691.JPG'
import image11 from '../../images/gdrive-pics/Cornbrook/KZFC8166.JPG'
import image12 from '../../images/gdrive-pics/Cornbrook/MJUA1710.JPG'
import image13 from '../../images/gdrive-pics/Cornbrook/PJCF4077.JPG'
import image14 from '../../images/gdrive-pics/Cornbrook/SLAQ0941.JPG'





const itemData = [
    {
      img: image1,
      title: '',
    },
    {
      img: image3,
      title: '',
    },
    {
      img: image4,
      title: '',
    },
    {
      img: image6,
      title: '',
    },
    {
      img: image7,
      title: '',
    },
    {
      img: image8,
      title: '',
    },
    {
      img: image9,
      title: '',
    },
    {
      img: image10,
      title: '',
    },
    {
      img: image11,
      title: '',
    },
    {
      img: image12,
      title: '',
    },
    {
      img: image13,
      title: '',
    },
    {
      img: image14,
      title: '',
    },    
  ];

 const ImageMasonry = (props) => {

  const [open, setOpen] = useState(false);
	const [backdrop] = useState(true);
  const [modalImage, setModalImage] = useState('');
  
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
  const handleCurrentImage = (image) => setModalImage(image);

  // display columbs
  
  const [display, setDisplay] = useState(4);

  const [width, setWidth] = useState(window.innerWidth);
  const mobile = 480;
  const tablet = 768;
  const desktop = 990;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    if (width < mobile) {
      setDisplay(1);
      
      
      return () => window.removeEventListener("resize", handleWindowResize);
    }

    if (width > mobile && width < tablet) {
      setDisplay(2);
      
      
      return () => window.removeEventListener("resize", handleWindowResize);
    } else if ( width > desktop ) {
      setDisplay(3);
      
      
      return () => window.removeEventListener("resize", handleWindowResize);
    } 
  }, [width, display]);

  
  
  
  return (
<>
        <Box sx={{ minHeight: 0 }} className='backgroundcolor'>
      <Masonry columns={display} spacing={1}>
        {itemData.map((item, index) => (
          <Stack key={index}>
            
            <img
              src={`${item.img}?w=16&auto=format`}
              srcSet={`${item.img}?w=16&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              className='images'
              onClick={() => {  handleCurrentImage(item.img); handleOpen();}}
            />
            
          </Stack>
        ))}
      </Masonry>
    </Box>
    {/* 
    
    <div className="modal-container">
    <Modal
      
      backdrop={backdrop}
      keyboard={false}
      open={open}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>
        {modalTitle} 
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <img
              src={`${modalImage}?w=400&auto=format`}
              srcSet={`${modalImage}?w=400&auto=format&dpr=2 2x`}
              alt=""
              loading="lazy"
              style={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
              
            />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  </div> 
  
  */}
</>

  );
}



export default ImageMasonry