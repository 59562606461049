import React from 'react'
import './infoBar.css'
import Info from './info'

const InfoBar = () => {

    

return (

<div className="infoBar fluid">
<div className="divider">|</div>    
<Info legend="Email">info@project5senses.co.uk</Info>
<Info legend="Phone Catherine">07940 767 393</Info>
<Info legend="Phone Vicky">07490 173 388</Info>
</div>

);
}

export default InfoBar