import React from 'react'
import './footer.css'
import { Row, Col } from 'react-bootstrap'
import ScrollToTop from '../hoc/scrollToTop/scrollToTop'

const Footer = () => {

    return (

        
<div className="main-footer">


<Row>
    <Col className="copywrite" >©2023 Project 5 Senses. All rights reserved.</Col>
    
    
    <Col><ScrollToTop /></Col> 
    
  </Row>
  
</div>


        
        
    );
}

export default Footer