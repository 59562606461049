import React from "react";
import "./header.css";
import InfoBar from "../infoBar/infoBar";
import NavbarItems from "../navbar/navbar";
// import HeaderBanner from "./banner";
import Logo from "../logo/logo";


const Header = () => {
	

	return (
		<div className="main-header">

			<div className="container-fluid">
				<InfoBar />
			</div>

		<div className="row containter-fluid">
				
				<div className="headerCarousel col-sm-12">
					
						{/* <HeaderBanner /> */}
					<Logo />
				</div>
				
		</div>
			<NavbarItems className="navBarItems" />
		</div>
	);
};

export default Header;
