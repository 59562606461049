import React from "react";
import { Card, Icon, Image } from 'semantic-ui-react'
import { Grid } from 'semantic-ui-react';
import vicki from '../../../images/Vicki-Barrow.png'
import tanya from '../../../images/Tanya-Martland.png'
import catherine from '../../../images/Catherine-Farrelly.png'
import joanne from '../../../images/Joanne-Carr.png'
import './meetTheTeam.css'
import Vacancies from '../../vacancies/vacancies'
import BreadcrumbList from '../../breadcrumb/breadcrumb'


const MeetTheTeam = () => {
   
  const sections = [
    { key: 'Home', content: 'Home', link: true },
    { key: 'MeetTheTeam', content: 'Meet The Team', active: true },
    
  ]
  

  
  

  return (

    <>
<div className="breadcrumb"><BreadcrumbList section={sections}/></div>

    <div>
      
      <div>
      <br />
      <h2 className="centered">
        
         Meet The Team
      </h2>
	  </div>
      <br />

	  <Grid>      



       <Grid.Row>

<Grid.Column mobile={16} tablet={16} computer={16}>

        <Card.Group centered className=''>
      
	  
	 <Card color='blue'>
    <Image src={vicki} wrapped ui={false} className='imageStyle' />
    <Card.Content>
      <Card.Header className="clear" >Vicki Barrow</Card.Header>
      <Card.Meta>Director<br />07490 173 388</Card.Meta>
      
    </Card.Content>
    <Card.Content extra>
      
        <Icon name='user' />
        
    </Card.Content>
  </Card>

  <Card color='blue'>
    <Image src={catherine} wrapped ui={false} className='imageStyle' />
    <Card.Content>
      <Card.Header className="clear" >Catherine Farrelly</Card.Header>
      <Card.Meta>Director<br />07490 767 393</Card.Meta>
      
    </Card.Content>
    <Card.Content extra>
     
        <Icon name='user' />
        
    </Card.Content>
  </Card>
  {/* </Card.Group> */}
  {/* </Grid.Column> */}
  {/* </Grid.Row> */}




  {/* <Grid.Row> */}
      {/* <Grid.Column mobile={16} tablet={12} computer={12}> */}
	  {/* <Card.Group centered className=''> */}
  <Card color='blue'>
    <Image src={tanya} wrapped ui={false} className='imageStyle' />
    <Card.Content>
      <Card.Header className="clear" >Tanya Martland</Card.Header>
      <Card.Meta>Support Worker<br /></Card.Meta>
      
    </Card.Content>
    <Card.Content extra>
      
        <Icon name='user' />
       
    </Card.Content>
  </Card>
  <Card color='blue'>
    <Image src={joanne} wrapped ui={false} className='imageStyle' />
    <Card.Content>
      <Card.Header className="clear" >Joanne Carr</Card.Header>
      <Card.Meta>Support Worker<br/></Card.Meta>
      
    </Card.Content>
    <Card.Content extra>
      
        <Icon name='user' />
        
    </Card.Content>
  </Card>



</Card.Group>


  </Grid.Column>

  

  </Grid.Row> 



	 </Grid>
	 <br />
      <br />
      <br />
      <br />
      <Vacancies />
    </div>
    
    </>
  );
};

export default MeetTheTeam;
