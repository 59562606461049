import React from "react";
import FrameBottom from "../hoc/frameBottom";
import Spacer from "../hoc/spacer";
import ColumnList from "../lists/columnList";
import './homeText.css'


const HomeText = () => {

  const customStyle = {
    color: "#000000",
    borderRadius: "0",
    textAlign: "centered",
  };
  

  return (
    <div id="about">
      <Spacer />
      <section>
      <article>
      <FrameBottom style={customStyle}>
          
        <h2 className="centered">5 Senses Social Care</h2>
        <br />
        <div className='aboutText'>
        Having a combined experience of over 40 years working in the disability
        field and other social care settings,<br />Vicki and Cath felt that
        individuals were not being listened too or their needs met.
        <br /><br />They both wanted to create a service that was bespoke and
        different to the traditional day services that they had encountered.
        <br /><br />
        5 Senses was established by Vicki and Cath in 2019, with the vision to
        create opportunities and meaningful activities for individuals using
        this social care service. <br /><br />
        They felt the best way to achieve this was offering day opportunities on
        a smaller scale, enabling the service to be individualised and more
        personal.
        <br /><br />
        We are currently operating from 2 properties in Skelmersdale, these are
        normal houses which create that home from home feel.<br /><br />Activities are varied and individuals have an input into the programme, promoting independence; choice and wellbeing, allowing individuals
        the opportunity to build on and learn new skills.
        <br /><br />
        Here at 5 Senses we offer a varied programme of activities to suit the needs of each individual.<br />We thrive on promoting choice as much as possible.<br /><br />
        Ideas and suggestions for the programme come from individuals who attend the service and also staff who get to know, what people like and dislike, and work the programme around them.
        <br /><br />Activities we have offered include:<br /><br />

        <ColumnList />

        <br />
        5 Senses prides themselves on having an experienced, committed,
        fun and friendly team of staff, offering the social care service that we
        had envisioned.
        </div>
      </FrameBottom>
      </article>
      </section>
    </div>
  );
};

export default HomeText;
