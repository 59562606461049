import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import './faqsContent.css'
import '../../../pages/App.css'
import  BreadcrumbList from "../../breadcrumb/breadcrumb"

const FaqsContent = () => {

  const sections = [
    { key: 'Home', content: 'Home', link: true },
    { key: 'MeetTheTeam', content: 'Meet The Team', link: true },
    { key: 'Faq', content: 'Faq', active: true },
  ]


return (


  <>
<div className="breadcrumb"><BreadcrumbList section={sections}/></div>


 <div>
   
<h2 className="center">Facts and Questions</h2>

  <div className="App-content faq-content">
  

<Accordion className="container ">
  <Accordion.Item eventKey="0"  className="w-100">
    <Accordion.Header>1. What are your opening days/times?</Accordion.Header>
    <Accordion.Body className="w-100" >    
We are open Monday to Friday from 9.30-2.30
Bespoke hours can be arranged, subject to availability.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>2. Can I attend during college holidays only?</Accordion.Header>
    <Accordion.Body>    
Yes, you can. We will agree and confirm dates with you in advance.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header> 3. How do I pay for attending 5 Senses?</Accordion.Header>
    <Accordion.Body>   
You can pay with your direct payments.
There are various methods to do this, manage your own, a supported banking system or have a parent/carer/responsible agency to do this for you.
Invoices are sent out by 5 Senses on a 4-weekly rolling rota.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header>4. Can I self fund?</Accordion.Header>
    <Accordion.Body>       
Yes you can. Invoices will be sent out to you by 5 Senses on a 4-weekly rolling rota.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4">
    <Accordion.Header>5. How do I get direct payments/funding to attend?</Accordion.Header>
    <Accordion.Body>       
    
You will need an assessment from the Adult Social Care team.<br/>Contact them on 0300 123 6720
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="5">
    <Accordion.Header>6. Do I have to pay if I don’t attend?</Accordion.Header>
    <Accordion.Body>      
Unfortunately, yes you do. Payments are based on the placement as a whole. You will be expected to sign our contract. This secures your place with 5 Senses and ensures your place is kept open. 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="6">
    <Accordion.Header>7. Do you offer transport?</Accordion.Header>
    <Accordion.Body>      
At the moment we do not offer personal pick-ups or drop-offs but other modes of transport and costings can be discussed.
   </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="7">
    <Accordion.Header>8. What activities do you offer at 5 Senses?</Accordion.Header>
    <Accordion.Body>     
We offer various inhouse activities with plenty of choice and stimulation.  Activities are discussed with individuals and incorporated into the programme as much as we can. 
   </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="8">
    <Accordion.Header>9. Do you offer day trips?</Accordion.Header>
    <Accordion.Body>    
Yes, we do. We try to get out and about in the community as much as possible.
</Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="9">
    <Accordion.Header>10. How do I get a place at 5 Senses?</Accordion.Header>
    <Accordion.Body>    
Just contact Vicki or Cath by the telephone number or email at the top of the page and we will it take it from there.
Or ask any professional to contact us on your behalf.
</Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="10">
    <Accordion.Header>11. I have more questions/queries?</Accordion.Header>
    <Accordion.Body>    
    
Don’t hesitate to telephone or email Vicki or Cath and we will be happy to help with any further questions/queries.


</Accordion.Body>
  </Accordion.Item>
</Accordion>





</div> 
</div>
</>

);



}

export default FaqsContent