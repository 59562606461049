import React from "react";
import { Container, Image } from "semantic-ui-react";
import Logo from "../../images/Logo.png";
import Spacer from "../hoc/spacer";
import FrameBottom from "../hoc/frameBottom";
import "./vacancies.css";

const Vacancies = () => {
  const customStyle = {
    color: "#000000",
    borderRadius: "0",
    textAlign: "center",
    
  };

  const bannerStyle = {
    
    display: 'inline',
    
  };

  return (
    <div>
      <Container textAlign="justified" className='vacancies' >
        <FrameBottom style={customStyle}>
          <div style={bannerStyle}>
            <Image src={Logo} fluid className='logo' id="vacancies" />
            
          </div>
          <Spacer />
          <h2 className="centered">Vacancies</h2>
          <br />
          <br />
          <br />
          
          <p>
            5 Senses have an ongoing recruitment
            <br /> so if your interested in joining our team of staff please
            contact:
            <br /><br /> Catherine : 07940 767 393
            <br /> Or
            
            <br /> Vicki : 07490 173 388
            <br /> info@project5senses.co.uk
          </p>
          <br />
        </FrameBottom>
      </Container>

      <Spacer />
    </div>
  );
};

export default Vacancies;
