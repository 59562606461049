import React from 'react';
import './frame.css';

const FrameBottom = (props) => {

return (

    <div className="container frame tl-radius tr-radius fb-padding" style={props.style} >

      {props.children}  

    </div>
);

}

export default FrameBottom