import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import './breadcrumb.css'




const BreadcrumbList = (props) => (

<div className='breadcrumb'>

  <Breadcrumb icon='right angle' sections={props.section}  className='breadcrumb' />
  
</div>
)

export default BreadcrumbList