import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

import image1 from '../../images/sd card/EIJM8588.jpg';
import image2 from '../../images/sd card/ELMI9674.jpg';

import image4 from '../../images/sd card/IMG_0468.jpg';
import image5 from '../../images/sd card/IMG_0664.jpg';
import image7 from '../../images/sd card/IMG_0865.jpg';
import image8 from '../../images/sd card/IMG_1615.jpg';
import image9 from '../../images/sd card/IMG_1689.jpg';
import image14 from '../../images/sd card/IMG_3552.jpg';
import image15 from '../../images/sd card/IMG_3580.jpg';
import image16 from '../../images/sd card/IMG_3627.jpg';
import image17 from '../../images/sd card/IMG_3643.jpg';
import image18 from '../../images/sd card/IMG_3657.jpg';
import image19 from '../../images/sd card/IMG_3744.jpg';
import image20 from '../../images/sd card/IMG_3798.jpg';
import image21 from '../../images/sd card/IMG_3814.jpg';
import image22 from '../../images/sd card/IMG_3858.jpg';
import image23 from '../../images/sd card/IMG_3871.jpg';


import image26 from '../../images/sd card/IMG_4106.jpg';
import image27 from '../../images/sd card/IMG_4109.jpg';
import image28 from '../../images/sd card/IMG_4485.jpg';
import image29 from '../../images/sd card/IMG_4621.jpg';

import image31 from '../../images/sd card/MRZF9353.jpg';
import image32 from '../../images/sd card/SFQS1678.jpg';

import image34 from '../../images/sd card/XKUY0016.jpg';
import image35 from '../../images/sd card/XUGO6735.jpg';


import './bootCarousel.css'


const BootCarousel = () => {



return (


<Carousel slide interval={5000} className="container">
  <Carousel.Item >
    <img
      className="d-block w-100 carousel"
      src={image20}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  

  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image4}
      alt="Fourth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image5}
      alt="Fifth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
 
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image7}
      alt="Seventh slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image8}
      alt="Eighth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
 
  
  
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image2}
      alt="Second slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>

  
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image14}
      alt="Forteenth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image16}
      alt="Sixteenth slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image17}
      alt="Seventeenth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image21}
      alt="Seventeenth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image18}
      alt="Eighteenth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image19}
      alt="Nineteenth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image1}
      alt="Twentieth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image22}
      alt="Twenty Second slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image23}
      alt="Twenty Third slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image26}
      alt="Twenty Fifth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image9}
      alt="Nineth slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image27}
      alt="Twenty Sixth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image28}
      alt="Twenty Seventh slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image15}
      alt="Fifteenth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image29}
      alt="Twenty Eighth slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image31}
      alt="Twenty Nineth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image32}
      alt="Thirtieth slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image34}
      alt="Thirty Second slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100 carousel"
      src={image35}
      alt="Thirty Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>





</Carousel>



);



}

export default BootCarousel