import React from "react";


const Spacer = () => {


const customStyle = {

    marginTop: '8vh',

};

return (

<div style={customStyle}>

</div>

);

}

export default Spacer