import React from "react";
import "./App.css";
import Content from "../Components/content/content";
import {Helmet} from 'react-helmet';

const Home = () => {

  return (

    <div>   
      <Helmet>
<title>5 Senses social Care</title>
<meta
      name="description"
      content="5 Senses is a social care setting for adults with disabilities. we offer a wide range of activities orientated around the individual." />


</Helmet>   
      <Content />
    </div>
    
  );
};

export default Home;
